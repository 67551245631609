import React from "react"
import LittleJoyceCoast from "../images/front-cover-coast.png"
import LittleJoyceOcean from "../images/front-cover-ocean.png"
import LittleJoyceRainforest from "../images/front-cover-rainforest.jpg"
import LittleJoyceSavannah from "../images/front-cover-savannah.png"
import LittleJoyceArctic from "../images/front-cover-arctic.jpg"
import MylosMonsters from "../images/mylos-monsters-cover.jpg"

function MyBooks(props) {
  return (
    <section className="overflow-hidden" id="myBooks">
      <div className="container py-lg-7 py-md-6 py-5 my-2 my-md-0">
        <h2 className="mb-3 text-center">My Books</h2>
        <p className="mb-4 pb-md-2 text-muted text-center">
          Written and illustrated by me!
        </p>
        <a href="#gibraltarOrder">
          <p className="mb-4 text-primary text-center">
            Live in Gibraltar?
          </p>
        </a>
        <div className="masonry-filterable">
          <div className="masonry-grid" data-columns="3">
            <article className="masonry-grid-item">
              <div className="card card-flip">
                <div className="card-flip-inner min-550">
                  <div className="card-flip-front">
                    <img src={LittleJoyceCoast} alt={"Little Joyce Coast Friends Cover"}
                                 className="card-img" />
                    <div className="card-body text-center">
                      <h3 className="h5 mb-1">Little Joyce: Coast Friends</h3>
                      <a
                        href="https://www.amazon.co.uk/Little-Joyce-Aidan-Charles-Serra/dp/B098D1PFFS/ref=sr_1_4?dchild=1&qid=1629557259&refinements=p_27%3AAidan+Charles+Serra&s=books&sr=1-4&text=Aidan+Charles+Serra"
                        target="_blank">
                        <button type="button" className="btn btn-primary btn-sm rounded-pill">Buy Now!</button>
                      </a>
                    </div>
                  </div>
                  <div className="card-flip-back">
                    <div className="card-body">
                      <div className="card-body-inner">
                        <h3 className="h5 card-title mb-2">Coast Friends</h3>
                        <p className="fs-sm text-muted">
                          Join Little Joyce as she travels the world looking for animal friends to play with. It can be particularly tricky to spot
                          some of the animals hiding away, so keep a sharp eye out! It seems Little Joyce is spending the day down the coast, what
                          type of friends will she meet there? Let's find out!
                        </p>
                        <p className="fs-sm text-muted">
                          Explore the world with the Little Joyce series!
                        </p>
                        <p className="fs-sm text-muted">
                          Packed with clever rhymes and fun storytelling, this lovable series introduces children to different biomes and the
                          wonderful creatures that roam the Earth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="masonry-grid-item">
              <div className="card card-flip">
                <div className="card-flip-inner min-550">
                  <div className="card-flip-front">
                    <img src={LittleJoyceOcean} alt={"Little Joyce Ocean Friends Cover"}
                                 className="card-img" />
                    <div className="card-body text-center">
                      <h3 className="h5 mb-1">Little Joyce: Ocean Friends</h3>
                      <a
                        href="https://www.amazon.co.uk/Little-Joyce-Aidan-Charles-Serra/dp/B098CQYZX4/ref=sr_1_3?dchild=1&qid=1629557259&refinements=p_27%3AAidan+Charles+Serra&s=books&sr=1-3&text=Aidan+Charles+Serra"
                        target="_blank">
                        <button type="button" className="btn btn-primary btn-sm rounded-pill">Buy Now!</button>
                      </a>
                    </div>
                  </div>
                  <div className="card-flip-back">
                    <div className="card-body">
                      <div className="card-body-inner">
                        <h3 className="h5 card-title mb-2">Ocean Friends</h3>
                        <p className="fs-sm text-muted">
                          Join Little Joyce as she travels the world looking for animal friends to play with. It can be particularly tricky to spot
                          some of the animals hiding away, so keep a sharp eye out! It seems Little Joyce is diving into the ocean today, what type of
                          friends will she meet there? Let's find out!
                        </p>
                        <p className="fs-sm text-muted">
                          Explore the world with the Little Joyce series!
                        </p>
                        <p className="fs-sm text-muted">
                          Packed with clever rhymes and fun storytelling, this lovable series introduces children to different biomes and the
                          wonderful creatures that roam the Earth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="masonry-grid-item">
              <div className="card card-flip">
                <div className="card-flip-inner min-550">
                  <div className="card-flip-front">
                    <img src={LittleJoyceRainforest} alt={"Little Joyce Rainforest Friends Cover"}
                                 className="card-img" />
                    <div className="card-body text-center">
                      <h3 className="h5 mb-1">Little Joyce: Rainforest Friends</h3>
                      <a
                        href="https://www.amazon.co.uk/Little-Joyce-Rainforest-Aidan-Serra/dp/B0986BDRJW/ref=sr_1_1?dchild=1&qid=1629557463&refinements=p_27%3AAidan+Serra&s=books&sr=1-1&text=Aidan+Serra"
                        target="_blank">
                        <button type="button" className="btn btn-primary btn-sm rounded-pill">Buy Now!</button>
                      </a>
                    </div>
                  </div>
                  <div className="card-flip-back">
                    <div className="card-body">
                      <div className="card-body-inner">
                        <h3 className="h5 card-title mb-2">Rainforest Friends</h3>
                        <p className="fs-sm text-muted">
                          Join Little Joyce as she travels the world looking for animal friends to play with. It can be particularly tricky to spot
                          some of the animals hiding away, so keep a sharp eye out! It seems Little Joyce has ventured into the rainforest today, what
                          type of friends will she meet there? Let's find out!
                        </p>
                        <p className="fs-sm text-muted">
                          Explore the world with the Little Joyce series!
                        </p>
                        <p className="fs-sm text-muted">
                          Packed with clever rhymes and fun storytelling, this lovable series introduces children to different biomes and the
                          wonderful creatures that roam the Earth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="masonry-grid-item">
              <div className="card card-flip">
                <div className="card-flip-inner min-550">
                  <div className="card-flip-front">
                    <img src={LittleJoyceSavannah} alt={"Little Joyce Savannah Friends Cover"}
                                 className="card-img" />
                    <div className="card-body text-center">
                      <h3 className="h5 mb-1">Little Joyce: Savannah Friends</h3>
                      <a
                        href="https://www.amazon.co.uk/Little-Joyce-Aidan-Charles-Serra/dp/B098FW4C5C/ref=sr_1_1?dchild=1&qid=1629557259&refinements=p_27%3AAidan+Charles+Serra&s=books&sr=1-1&text=Aidan+Charles+Serra"
                        target="_blank">
                        <button type="button" className="btn btn-primary btn-sm rounded-pill">Buy Now!</button>
                      </a>
                    </div>
                  </div>
                  <div className="card-flip-back">
                    <div className="card-body">
                      <div className="card-body-inner">
                        <h3 className="h5 card-title mb-2">Savannah Friends</h3>
                        <p className="fs-sm text-muted">
                          Join Little Joyce as she travels the world looking for animal friends to play with. It can be particularly tricky to spot
                          some of the animals hiding away, so keep a sharp eye out! It seems Little Joyce is exploring the savannah today! What type
                          of friends will she meet there? Let's find out!
                        </p>
                        <p className="fs-sm text-muted">
                          Explore the world with the Little Joyce series!
                        </p>
                        <p className="fs-sm text-muted">
                          Packed with clever rhymes and fun storytelling, this lovable series introduces children to different biomes and the
                          wonderful creatures that roam the Earth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="masonry-grid-item">
              <div className="card card-flip">
                <div className="card-flip-inner min-550">
                  <div className="card-flip-front">
                    <img src={LittleJoyceArctic} alt={"Little Joyce Arctic Friends Cover"}
                         className="card-img" />
                    <div className="card-body text-center">
                      <h3 className="h5 mb-1">Little Joyce: Arctic Friends</h3>
                      <a
                        href="https://www.amazon.co.uk/Little-Joyce-Aidan-Charles-Serra/dp/B09L3PP1HK/ref=sr_1_4?qid=1638025251&qsid=261-3273121-0007456&refinements=p_27%3AAidan+Charles+Serra&s=books&sr=1-4&sres=B098FW4C5C%2CB098D1PFFS%2CB098CQYZX4%2CB09L3PP1HK%2CB09CC4JCCK&text=Aidan+Charles+Serra"
                        target="_blank">
                        <button type="button" className="btn btn-primary btn-sm rounded-pill">Buy Now!</button>
                      </a>
                    </div>
                  </div>
                  <div className="card-flip-back">
                    <div className="card-body">
                      <div className="card-body-inner">
                        <h3 className="h5 card-title mb-2">Arctic Friends</h3>
                        <p className="fs-sm text-muted">
                          Join Little Joyce as she travels the world looking for animal friends to play with. It can be particularly tricky to spot
                          some of the animals hiding away, so keep a sharp eye out! It seems Little Joyce is exploring the arctic today! What type
                          of friends will she meet there? Let's find out!
                        </p>
                        <p className="fs-sm text-muted">
                          Explore the world with the Little Joyce series!
                        </p>
                        <p className="fs-sm text-muted">
                          Packed with clever rhymes and fun storytelling, this lovable series introduces children to different biomes and the
                          wonderful creatures that roam the Earth.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="masonry-grid-item">
              <div className="card card-flip">
                <div className="card-flip-inner min-550">
                  <div className="card-flip-front">
                    <img src={MylosMonsters} alt={"Mylo's Monsters Cover"}
                                 className="card-img" />
                    <div className="card-body text-center">
                      <h3 className="h5 mb-1">Mylo's Monsters</h3>
                      <a
                        href="https://www.amazon.co.uk/Mylos-Monsters-Aidan-Charles-Serra/dp/B09CC4JCCK/ref=sr_1_2?dchild=1&qid=1629557259&refinements=p_27%3AAidan+Charles+Serra&s=books&sr=1-2&text=Aidan+Charles+Serra"
                        target="_blank">
                        <button type="button" className="btn btn-primary btn-sm rounded-pill">Buy Now!</button>
                      </a>
                    </div>
                  </div>
                  <div className="card-flip-back">
                    <div className="card-body">
                      <div className="card-body-inner">
                        <h3 className="h5 card-title mb-2">Mylo's Monsters</h3>
                        <p className="fs-sm text-muted">
                          Mylo has monsters living under his bed!
                        </p>
                        <p className="fs-sm text-muted">
                          Should he be afraid? Of course not!
                        </p>
                        <p className="fs-sm text-muted">
                          Monsters come in all shapes and sizes, but they all have one thing in common...
                        </p>
                        <p className="fs-sm text-muted fw-bold">
                          THEY WANT TO BE YOUR FRIEND!
                        </p>
                        <p className="fs-sm text-muted">
                          A warm, colourful story about monsters who teach us not to judge appearances.
                        </p>
                        <p className="fs-sm text-muted">
                          Monsters have feelings too!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyBooks
