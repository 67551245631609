import React from "react"
import { StaticImage } from "gatsby-plugin-image"

function AboutMe(props) {
  return (
    <section className="bg-secondary position-relative py-md-7 py-5">
      <div className="container-fluid my-2 my-md-0">
        <div className="row align-items-center">
          <div className="col-md-6 mb-md-0 mb-5 pb-md-0 pb-3">
            <StaticImage src={"../images/about-me.png"} alt={"About Me"} className="d-block mx-md-0 mx-auto" />
          </div>
          <div className="col-md-6 text-md-start text-center">
            <div className="mx-md-0 mx-auto" style={{ maxWidth: 500 }}>
              <h2 className="mb-md-5 mb-4">A little bit about me</h2>
              <div className="d-md-flex align-items-start d-block mb-4 pb-2">
                <div className="ps-md-2">
                  <h3 className="h6 mb-2">Inspired youth..</h3>
                  <p className="mb-0 fs-sm">
                    For as long as I can remember, I have shared a passion for storytelling. Since the moment I could pick up a
                    pencil, I have experimented with comic books, graphic novels, short stories and film. My father is a retired actor and active film
                    producer and it seems the love for movies has trickled down to his sons. Movies were a huge part of my upbringing and have heavily
                    influenced the way I perceive the world. My brain seemed to cast a storytelling lens before my eyes, whereby it would conjure up
                    ideas for movies, television and novels. Where best to express this overflowing creativity? Short story competitions!
                  </p>
                </div>
              </div>
              <div className="d-md-flex align-items-start d-block mb-4 pb-2">
                <div className="ps-md-2">
                  <h3 className="h6 mb-2">Short stories..</h3>
                  <p className="mb-0 fs-sm">
                    I would write short stories for fun, and this love intensified as time went on. The thought of ‘making it official’ has always
                    lingered in my mind. Often, we find it easier to delay working on our projects and ultimately, never get around to completing our
                    work or chasing our dreams. Through their continuous support and encouragement, my parents and girlfriend played a crucial role in
                    making this happen
                  </p>
                </div>
              </div>
              <div className="d-md-flex align-items-start d-block">
                <div className="ps-md-2">
                  <h3 className="h6 mb-2">My motivation..</h3>
                  <p className="mb-5 fs-sm">
                    The motivation to create the Little Joyce series came from a very difficult time in my life. Three of my grandparents had passed
                    away in quick succession, each as kind and supportive as the other. Amongst them, my grandmother, Joyce, whom these books are
                    named after, held an even greater passion for reading than I did. I had declared to her, that I would someday write a book and
                    that she would read it, but I never actually got around to writing one. From a very dark moment, I found the brightest source of
                    inspiration, and whilst my grandmother may never read my book, I hope I can at least immortalise her through this series, which I
                    have written in her honour.
                  </p>
                  <p className="mb-0 fs-sm">
                    I hope parents and children alike can enjoy my stories and create beautiful memories like the ones I
                    have of my grandparents.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape shape-top shape-curve bg-body">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
          <path fill="currentColor" d="M3000,185.4V0H0v185.4C496.4,69.8,996.4,12,1500,12S2503.6,69.8,3000,185.4z"></path>
        </svg>
      </div>
    </section>
  )
}

export default AboutMe
