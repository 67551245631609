import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import AboutMe from "../components/about-me"
import MyBooks from "../components/my-books"
import OrderForm from "../components/order-form"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const IndexPage = () => (

  <Layout>
    <Seo title="Home" />
    <Hero />
    <AboutMe />
    <MyBooks />
    <OrderForm />
  </Layout>
)

export default IndexPage
