import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useFormik, Field, FormikProvider } from "formik"
import { navigate } from "gatsby"

function OrderForm(props) {
  const validate = values => {
    const errors = {}

    if (values.littleJoyceComplete && !values.littleJoyceQuantity) {
      errors.littleJoyce = "You need to select a quantity for Little Joyce if you want the books!"
    }

    if (values.littleJoyceQuantity && !values.littleJoyceComplete) {
      errors.littleJoyce = "You need to tick the box if you want the books!"
    }

    if (values.mylosMonsters && !values.mylosMonstersQuantity) {
      errors.mylosMonsters = "You need to select a quantity for Mylo's Monsters if you want the book!"
    }

    if (values.mylosMonstersQuantity && !values.mylosMonsters) {
      errors.mylosMonsters = "You need to tick the box if you want the books!"
    }

    if (values.theCompleteSet && !values.theCompleteSetQuantity) {
      errors.completeSet = "You need to select a quantity for the Complete Set if you want the book!"
    }

    if (values.theCompleteSetQuantity && !values.theCompleteSet) {
      errors.completeSet = "You need to tick the box if you want the books!"
    }

    return errors
  }

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }


  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      littleJoyceComplete: false,
      mylosMonsters: false,
      theCompleteSet: false,
      littleJoyceQuantity: "",
      mylosMonstersQuantity: "",
      theCompleteSetQuantity: ""
    },
    validate,
    onSubmit: (values) => {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "order",
          ...values
        })
      }).then(() => navigate("/order-success")).catch(error => alert(error))
    }
  })

  return (
    <div>
      <StaticImage src="../images/gibraltar-promo.jpg" alt="Gibraltar Promo" />

      <section className="bg-secondary position-relative py-md-7 py-5" id="gibraltarOrder">
        <div className="container py-lg-7 py-md-6 py-5 my-2 my-md-0">
          <h2 className="mb-3 text-center">
            Gibraltar Order Form
          </h2>
          <p className="text-muted text-center">
            If you're from Gibraltar, order using the form below and you'll get a discounted price and free delivery! I'll contact you directly with a
            special price and delivery arrangements.
          </p>

          <FormikProvider value={formik}>
            <form name="order" data-netlify="true" data-netlify-honeypot="bot-field" method="post" onSubmit={formik.handleSubmit}>
              <input type="hidden" name="form-name" value="order" />
              <div className="row">
                <div className="col-sm-6 mb-3 pb-1">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Your name</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="Your name"
                      id="name"
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name} />
                  </div>
                </div>
                <div className="col-sm-6 mb-3 pb-1">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input required
                           type="text"
                           className="form-control"
                           placeholder="Your email"
                           id="email"
                           name="email"
                           onChange={formik.handleChange}
                           value={formik.values.email} />
                  </div>
                </div>
              </div>
              <div className="row">
                {/*Little Joyce Complete Set*/}
                <div className="col-sm-6 mb-3 pb-1">
                  <div className="form-check form-check-inline">
                    <Field className="form-check-input"
                           type="checkbox"
                           id="littleJoyceComplete"
                           name="littleJoyceComplete"
                           onChange={formik.handleChange} />

                    <label className="form-check-label" htmlFor="littleJoyceComplete">The Little Joyce Set (£25)</label>
                    <div className="form-text">
                      All 5 Little Joyce books including Little Joyce: Arctic Friends
                    </div>
                  </div>
                </div>

                <div className="col-sm-3 mb-3 pb-1">
                  <input type="number"
                         className="form-control"
                         placeholder="Little Joyce Amount"
                         id="littleJoyceQuantity"
                         name="littleJoyceQuantity"
                         onChange={formik.handleChange}
                         value={formik.values.littleJoyceQuantity} />
                  <div className="form-text text-danger">
                    {formik.errors.littleJoyce ? formik.errors.littleJoyce : null}
                  </div>
                </div>

                {/* The Complete Set */}
                <div className="col-sm-6 mb-3 pb-1">
                  <div className="form-check form-check-inline">
                    <Field className="form-check-input"
                           type="checkbox"
                           id="theCompleteSet"
                           name="theCompleteSet"
                           onChange={formik.handleChange} />

                    <label className="form-check-label" htmlFor="theCompleteSet">The Complete Set (£31)</label>
                    <div className="form-text">
                      This includes the whole Little Joyce set & Mylo's Monsters
                    </div>
                  </div>
                </div>

                <div className="col-sm-3 mb-3 pb-1">
                  <input type="number"
                         className="form-control"
                         placeholder="The Complete Set Amount"
                         id="theCompleteSetQuantity"
                         name="theCompleteSetQuantity"
                         onChange={formik.handleChange}
                         value={formik.values.theCompleteSetQuantity} />
                  <div className="form-text text-danger">
                    {formik.errors.completeSet ? formik.errors.completeSet : null}
                  </div>
                </div>

                {/* Mylos Monsters*/}

                <div className="col-sm-6 mb-3 pb-1">
                  <div className="form-check form-check-inline">
                    <Field className="form-check-input"
                           type="checkbox"
                           id="mylosMonsters"
                           name="mylosMonsters"
                           onChange={formik.handleChange} />
                    <label className="form-check-label" htmlFor="mylosMonsters">Mylo's Monsters (£6)</label>
                    <div className="form-text">
                      With every purchase, &#163;1 will be donated to Cancer Relief. &#163;6 for the book.
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 mb-3 pb-1">
                  <input type="number"
                         className="form-control"
                         placeholder="Mylo's Monsters Amount"
                         id="mylosMonstersQuantity"
                         name="mylosMonstersQuantity"
                         onChange={formik.handleChange}
                         value={formik.values.mylosMonstersQuantity} />
                  <div className="form-text text-danger">
                    {formik.errors.mylosMonsters ? formik.errors.mylosMonsters : null}
                  </div>
                </div>
              </div>
              <div className="row text-center mb-5">
                <div className="text-secondary">
                  If you wish to purchase Little Joyce: Arctic Friends individually, please contact me via Facebook or Instagram! Thank you!
                </div>
              </div>
              <div className="row text-center">
                <div>
                  <button type="submit" className="btn btn-primary btn-sm rounded-pill">Reserve Now!</button>
                </div>
              </div>
            </form>
          </FormikProvider>

        </div>
        <div className="shape shape-top shape-curve-side bg-body">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 250">
            <path fill="currentColor" d="M3000,250V0H0v51c572.7-34.3,1125.3-34.3,1657.8,0C2190.3,85.2,2637.7,151.6,3000,250z"></path>
          </svg>
        </div>
      </section>
    </div>

  )
}

export default OrderForm
