import React from "react"
import Loadable from "@loadable/component"
import { StaticImage } from "gatsby-plugin-image"

function Hero(props) {

  const LoadableCarousel = Loadable(() => import("./carousel"))

  return (
    <section className="mb-3 my-md-0">
      <div className="tns-carousel-wrapper tns-controls-inside">
        <LoadableCarousel />
      </div>
      <div className="container text-center position-relative w-50">
        <StaticImage src="../images/aidan-photo.png" className="mx-auto"
                     style={{transform: "translateY(-20%)"}}
                     alt="Aidan Charles Serra - it's me!" placeholder="tracedSVG" />
      </div>
      <div className="container">
        <div className="row align-items-center mb-5">
          <div className="col-md-12 text-center">
            <h1 className="display-4 mb-2 text-primary">
              Hi, I'm Aidan! Welcome to my website.
            </h1>
            <h3 className="mb-lg-5 mb-4 text-secondary">
              Author &amp; Illustrator
            </h3>
            <div className="text-nowrap"><a className="btn btn-translucent-primary me-3" href="#myBooks">
              <i className="ai-book-open mt-n1 me-2"></i> My Books</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
